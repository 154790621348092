import React, { FunctionComponent, useEffect, useState } from "react"
import axios from "axios"
import { VictoryPie } from "victory-pie"
import VisitorDistribution from "../../components/VisitorDistribution"
import { utmCookieRequest } from "../../utils/handleUTFTracking"
interface TrackerPageProps {
  serverData: any
}

export const apiResponse = {
  responses: [],
  uniqueParticipants: 0,
  socialsCreated: 0,
  socialsCreatedHistory: [
    {
      time: 120,
      value: 0,
    },
    {
      time: 132,
      value: 0,
    },
    {
      time: 137,
      value: 0,
    },
    {
      time: 139,
      value: 0,
    },
    {
      time: 141,
      value: 0,
    },
    {
      time: 143,
      value: 0,
    },
    {
      time: 145,
      value: 0,
    },
    {
      time: 147,
      value: 0,
    },
    {
      time: 371,
      value: 0,
    },
    {
      time: 373,
      value: 0,
    },
    {
      time: 375,
      value: 0,
    },
    {
      time: 377,
      value: 0,
    },
    {
      time: 379,
      value: 0,
    },
    {
      time: 381,
      value: 0,
    },
  ],
  socialsStarted: 0,
  mixersPlayed: 0,
  browsers: {
    values: [
      "Chrome 94",
      "Chrome Mobile 94",
      "Chrome Mobile 95",
      "Safari 15",
      "Mobile Safari 14",
      "Chrome 93",
      "Chrome 95",
      "Edge 94",
      "Firefox 93",
      "Safari 14",
      "Chrome 92",
      "Chrome 64",
      "Chrome 79",
      "Chrome 90",
      "Chrome 91",
      "Chrome Mobile 84",
      "Chrome Mobile 92",
      "Firefox 38",
      "Firefox 78",
    ],
    ratios: [43, 13, 6, 6, 4, 3, 3, 3, 3, 3, 2, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  countries: {
    values: [
      "India",
      "United States",
      "United Kingdom",
      "Canada",
      "Czech Republic",
      "Germany",
      "Japan",
      "Macedonia",
      "Portugal",
      "Singapore",
      "Turkey",
    ],
    ratios: [76, 10, 2, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  operatingSystems: {
    values: ["Mac", "Android", "Windows", "Apple iPhone", "Linux"],
    ratios: [56, 16, 14, 10, 1],
  },
  uniqueVisitors: 96,
  uniqueVisitorsHistory: [
    {
      time: 120,
      value: 83,
    },
    {
      time: 132,
      value: 83,
    },
    {
      time: 137,
      value: 88,
    },
    {
      time: 139,
      value: 93,
    },
    {
      time: 141,
      value: 93,
    },
    {
      time: 143,
      value: 94,
    },
    {
      time: 145,
      value: 94,
    },
    {
      time: 147,
      value: 95,
    },
    {
      time: 371,
      value: 95,
    },
    {
      time: 373,
      value: 95,
    },
    {
      time: 375,
      value: 95,
    },
    {
      time: 377,
      value: 96,
    },
    {
      time: 379,
      value: 96,
    },
    {
      time: 381,
      value: 96,
    },
  ],
  lovedIt: 0,
}

const TrackerPage: FunctionComponent<TrackerPageProps> = ({ serverData }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const d = [
      { x: "chrome" },
      { x: "safari" },
      { x: "firefox" },
      { x: "edge" },
      { x: "others" },
    ]

    const newData = d.map((val, ind) => {
      val.y = serverData.randomResp.number[ind]
      return val
    })

    setData(newData)
  }, [])

  useEffect(() => {
    utmCookieRequest()
  }, [])

  return (
    <>
      {data.length && window && (
        <VictoryPie
          animate={{
            onLoad: {
              duration: 10000,
            },
            easing: "exp",
          }}
          width={800}
          labels={({ datum }) => `${datum.y}:${datum.x}`}
          colorScale={["tomato", "orange", "gold", "cyan", "navy"]}
          data={data}
        />
      )}
    </>
  )
}

export default TrackerPage

export const getServerData = async () => {
  const resp = await axios.post(
    "https://random.api.randomkey.io/v1/number",
    {
      digits: 3,
      records: 5,
    },
    {
      headers: { auth: "4335bc70c2e16c8623caf8723fbc20f1" },
    }
  )

  console.log(resp.data)

  return {
    props: {
      randomResp: resp.data,
    },
  }
}
